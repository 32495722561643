/**
 * Leaderboard Page
 * 
 * Show each of the teams, in order of points, so that teams can see
 * where they stand in relation to other teams
 */

import React, { useContext, useState, useEffect } from 'react'
import AppContext from '../utils/context'
import LoggedInWrapper from '../components/loggedinWrapper'
import API from '../utils/api'
import { getCurrentHuntData } from '../utils/hunt-data'
import { getCurrentTeamData } from '../utils/team-data'

const LeaderboardPage = ({}) => {
  const context = useContext(AppContext)
  const [ leaders, setLeaders ] = useState([])
  const [ initialLoad, setInitialLoad ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const hunt_data = getCurrentHuntData()
  const team_data = getCurrentTeamData()

  const getLeaders = () => {
    if(!isLoading){
      setIsLoading(true)
      API.get(`hunt/${hunt_data.hunt.id}/leaderboard/`).then(res => {
        // console.log(res)
        setLeaders(res.data.teams)
        context.setLoading(false)
        setIsLoading(false)
      })
    }
  }

  useEffect(() => {
    if(!initialLoad){
      getLeaders()
      context.setLoading(true)
      setInitialLoad(true)
    }
    // fetch the leaderboard every 10 seconds while on this page
    const interval = setInterval(() => {
      getLeaders()
    }, 10000)

    // cleanup interval
    return () => {
      clearInterval(interval)
    }
  })

  return (
    <LoggedInWrapper>
      <div className="pt-3 pb-5 px-3 mb-3">
        <div className="card card-leaderboard mb-3">
          <div className="card-header"><h1 className="mb-0 text-center">Leaderboard</h1></div>
          <div className="card-body">
            <div className="row">
              <div className="col text-uppercase"><small>Team Name</small></div>
              <div className="col text-right text-uppercase"><small>Current Score</small></div>
            </div>
            {leaders.map(function(team, idx){
              let is_active_team = team.id == team_data.id
              return(
                <div key={`team-${team.id}`} className={`team-row row py-2 ${is_active_team ? `active` : ``}`}>
                  <div className="col"><h5 className="text-uppercase mb-0 font-weight-bold">{team.team_name ? team.team_name : `Unnamed Team`}</h5></div>
                  <div className="col flex-grow-0 text-right"><p className="mb-0 font-weight-bold">{team.total_score}</p></div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </LoggedInWrapper>
  )
}

export default LeaderboardPage